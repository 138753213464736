// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import { staticFactory } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      billDetails: [],
      months: [],
      totalAmount: 0,
      totalPaid: 0,
      billId: 0
    }
  },
  methods: {
    print: function() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print')?.innerHTML

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')!

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    }
  },
  created() {
    this.billId = parseInt(this.$route.query.id)
  },
  async mounted () {
    await Promise.all([
      staticFactory.billDetail(),
      staticFactory.months()
    ]).then(results => {
      this.billDetails = results[0],
      this.months = results[1]
    })

    var totalAmount = 0
    this.billDetails.forEach((detail:any) => {
      if (detail.status != 1 && detail.published == 1 && (detail.id == this.billId || this.billId == 0)) {
        totalAmount += detail.total_amount
      }
    })

    this.totalAmount = totalAmount

    this.billDetails = this.billDetails.filter((detail:any) => detail.status != 1 && detail.published == 1 && (detail.id == this.billId || this.billId == 0))
  },

})

export default class UserInvoice extends Vue {}
