import axios from 'axios'

class SetupFactory {
  checkVersion () {
    return axios.get('/')
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error.response.data
      })
  }

  getFeatureConfigs(payload) {
    return axios.get('/feature-configs', {params: payload})
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error.response.data
      })
  }
}

export let setupFactory = new SetupFactory()
