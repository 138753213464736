
// @ts-nocheck
  import { Vue, Options } from 'vue-class-component'

  @Options({
    data() {
      return {
        links: [
        {
            id: 1,
            text: 'Jurnal Umum',
            page: '/acc/journal'
        },
        {
            id: 2,
            text: 'Master Akun',
            page: '/acc/account'
        },
        {
            id: 3,
            text: 'Laporan',
            page: '/acc/report'
        },
        {
            id: 4,
            text: 'Pengaturan',
            page: '/acc/setting'
        }]
      }
    }
  })

  export default class NavbarAcc extends Vue {}
