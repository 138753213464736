import { RouteRecordRaw } from 'vue-router'
import Navbar from '@/components/Navbar.vue'
import NavbarAcc from '@/components/NavbarAcc.vue'

const LoginPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "loginpage" */'@/views/default/LoginPage.vue')
const Dashboard = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard" */'@/views/default/Dashboard.vue')
const Bill = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill" */'@/views/default/Bill.vue')
const BillDetail = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill-detail" */'@/views/default/BillDetail.vue')
const BillType = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill-type" */'@/views/default/BillType.vue')
const BillDetailType = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill-type" */'@/views/default/BillDetailType.vue')
const BillUser = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill-user" */'@/views/default/BillUser.vue')
const BillUserImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "bill-user" */'@/views/default/BillUserImport.vue')
const Payment = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "payment" */'@/views/default/Payment.vue')
const VAImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "payment" */'@/views/default/VAImport.vue')
const UserBill = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user-bill" */'@/views/default/UserBill.vue')
const UserInvoice = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user-invoice" */'@/views/default/UserInvoice.vue')
const UserPayment = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user-payment" */'@/views/default/UserPayment.vue')
const Auth = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "auth" */'@/views/default/Auth.vue')
const ConvenienceFee = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "convenience-fee" */'@/views/default/ConvenienceFee.vue')
const Configurations = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "payment" */'@/views/default/Configurations.vue')

const Account = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "account" */'@/views/accounting/Account.vue')
const Journal = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "journal" */'@/views/accounting/Journal.vue')
const Setting = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "setting" */'@/views/accounting/Setting.vue')
const Report = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "report" */'@/views/accounting/Report.vue')

export const defaultRoutes: Array<RouteRecordRaw> = [
  {
    path: '/loginpage',
    name: 'Login Page',
    components: {
      // navbar: Navbar,
      content: LoginPage
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    components: {
      navbar: Navbar,
      content: Dashboard
    }
  },
  {
    path: '/auth/:instance/:email/:role_id/:signature',
    name: 'Auth',
    components: {
      content: Auth
    }
  },
  {
    path: '/auth',
    name: 'Auth Error',
    components: {
      content: Auth
    }
  },
  {
    path: '/bill',
    name: 'Bill',
    components: {
      navbar: Navbar,
      content: Bill
    }
  },
  {
    path: '/bill/:user_id',
    name: 'Bill Detail',
    components: {
      navbar: Navbar,
      content: BillDetail
    }
  },
  {
    path: '/bill/type',
    name: 'Bill Type',
    components: {
      navbar: Navbar,
      content: BillType
    }
  },
  {
    path: '/bill_detail/type',
    name: 'Bill Detail Type',
    components: {
      navbar: Navbar,
      content: BillDetailType
    }
  },
  {
    path: '/bill/user',
    name: 'Bill User',
    components: {
      navbar: Navbar,
      content: BillUser
    }
  },
  {
    path: '/bill/user/import',
    name: 'Bill User Import',
    components: {
      navbar: Navbar,
      content: BillUserImport
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    components: {
      navbar: Navbar,
      content: Payment
    }
  },
  {
    path: '/va-import',
    name: 'VA Import',
    components: {
      navbar: Navbar,
      content: VAImport
    }
  },
  {
    path: '/user/bill',
    name: 'User Dashboard',
    components: {
      content: UserBill
    }
  },
  {
    path: '/user/invoice/:order_id',
    name: 'User Invoice',
    components: {
      content: UserInvoice
    }
  },
  {
    path: '/user/payment',
    name: 'User Payment',
    components: {
      content: UserPayment
    }
  },
  {
    path: '/convenience/fee',
    name: 'Convenience Fee',
    components: {
      navbar: Navbar,
      content: ConvenienceFee
    }
  },
  // Account menu section
  {
    path: '/acc/account',
    name: 'Account',
    components: {
      navbar: NavbarAcc,
      content: Account
    }
  },
  {
    path: '/acc/journal',
    name: 'Journal',
    components: {
      navbar: NavbarAcc,
      content: Journal
    }
  },
  {
    path: '/acc/setting',
    name: 'Setting',
    components: {
      navbar: NavbarAcc,
      content: Setting
    }
  },
  {
    path: '/acc/report',
    name: 'Report',
    components: {
      navbar: NavbarAcc,
      content: Report
    }
  },
  {
    path: '/configs',
    name: 'Configurations',
    components: {
      navbar: Navbar,
      content: Configurations
    }
  },
]
