import axios from 'axios';
import authHeader from '@/http/auth.header'

class AuthService {
  async login(user:any) {
    try {
      var response = await axios.post('/data/months.json', {
        email: user.email,
        password: user.password
      })
      if (response.data.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        localStorage.setItem('accessToken', JSON.stringify(response.data.data.token))
        localStorage.setItem('refreshToken', JSON.stringify(response.data.data.refresh_token))
      }
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async token(data:any) {
    try {
      let url = '/auth/signature'
      var response = await axios.post(url, data)
      if (response.data.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        localStorage.setItem('accessToken', JSON.stringify(response.data.data.token))
        localStorage.setItem('refreshToken', JSON.stringify(response.data.data.refresh_token))
        axios.defaults.headers.common = authHeader()
      }
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  async refreshAccessToken() {
    try {
      let url = '/auth/refresh'
      var response = await axios.post(url)
      if (response.data.data.token) {
        localStorage.setItem('accessToken', JSON.stringify(response.data.data.token))
      }
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  async authLogin(data:any) {
    try {
      let url = '/auth/login'
      var response = await axios.post(url, data)
      if (response.data.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        localStorage.setItem('accessToken', JSON.stringify(response.data.data.token))
        localStorage.setItem('refreshToken', JSON.stringify(response.data.data.refresh_token))
        axios.defaults.headers.common = authHeader()
      }
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export let authService = new AuthService()
