
  // @ts-nocheck

  import { Vue, Options } from 'vue-class-component'
  import { setupFactory } from '@/http/setup'

  @Options({
    data() {
      return {
        links: [
        {
            id: 1,
            text: 'Rekap',
            page: '/'
        },
        {
            id: 2,
            text: 'Master Tagihan',
            page: '/bill'
        },
        {
            id: 3,
            text: 'Tagihan Siswa',
            page: '/bill/user'
        },
        {
            id: 4,
            text: 'Pembayaran',
            page: '/payment'
        }]
      }
    },
    async created() {
      Promise.resolve(setupFactory.getFeatureConfigs({feature_configs: ['show_US_import_VA']})).then(
        (res) => {
          if('show_US_import_VA' in res.data.feature_configs && res.data.feature_configs.show_US_import_VA && res.data.feature_configs.show_US_import_VA == 'true'){
            this.links.push(
              {
                id: 5,
                text: 'Impor VA',
                page: '/va-import'
              }
            )
          }
        }
      )
      // this.links.push(
      //   {
      //     id: 6,
      //     text: 'Konfigurasi',
      //     page: '/configs'
      //   }
      // )
    },
  })

  export default class Navbar extends Vue {}
