// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import { staticFactory } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      billDetails: [],
      userPayments: [],
      months: [],
      totalAmount: 0,
      totalSelecttedAmount: 0,
      totalPaid: 0,
      billIds: [],
      allSelectedBill: false
    }
  },
  methods: {
    checkAllBill: function() {
      this.billIds = [];
      if (!this.allSelectedBill) {
          this.billDetails.forEach((detail:any) => {
            this.billIds.push(detail.id)
          })
      }
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    }
  },
  computed: {
    totalSelectedAmount: function () {
      var totalSelectedAmount = 0
      this.billDetails.forEach((detail:any) => {
        if (detail.status != 1 && detail.published == 1) {
          if (this.billIds.indexOf(detail.id) != -1) {
            totalSelectedAmount += detail.total_amount
          }
        }
      })
      return totalSelectedAmount.toLocaleString()
    }
  },
  async mounted () {
    await Promise.all([
      staticFactory.billDetail(),
      staticFactory.months(),
      staticFactory.userPaymentHistory()
    ]).then(results => {
      this.billDetails = results[0],
      this.months = results[1],
      this.userPayments = results[2].reverse()
    })

    var totalAmount = 0
    var totalPaid = 0
    this.billDetails.forEach((detail:any) => {
      if (detail.status == 1) {
        totalPaid += detail.total_amount
      } else if (detail.status != 1 && detail.published == 1) {
        totalAmount += detail.total_amount
      }

    })

    this.totalAmount = totalAmount
    this.totalPaid = totalPaid

    this.billDetails = this.billDetails.filter((detail:any) => detail.status != 1 && detail.published == 1)

  },

})

export default class UserBill extends Vue {}
