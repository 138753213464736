
  // @ts-nocheck
  import { masterDataFactory } from '@/http/master-data'
  import { Vue, Options } from 'vue-class-component'

  @Options({
    props: {
      title: String,
      link1: String,
      link2: String,
    },
    computed () {
      this.link1 = this.title
      if (!this.link1) {
      }
      if (this.link1 && !this.link2) {
        this.link2 = this.title
      }
    }, 
    data() {
      return {
        dataReady: false,
        setting: {
          'SPP_PAYMENT_TITLE': 'Tagihan SPP Siswa',
          'SPP_PAYMENT_SUBTITLE': 'Pembayaran SPP',
        },
      }
    },
    methods: {
      loadDataMaster: function() {
        return Promise.all([
          masterDataFactory.setting_data({ title : 'SPP_PAYMENT_TITLE' }), 
          masterDataFactory.setting_data({ title : 'SPP_PAYMENT_SUBTITLE' })
        ]).then(results => {
          var setting = results[0].data.setting || null;

          if (setting) {
            var value = setting.value || ''
            var title = setting.title || ''

            if (value.length > 0) {
              this.setting[title] = value
            }
          }

          var setting = results[1].data.setting || null

          if (setting) {
            var value = setting.value || ''
            var title = setting.title || ''

            if (value.length > 0) {
              this.setting[title] = value
            }
          }
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
      },
    }, 
    async mounted () {
      await this.loadDataMaster()

      this.dataReady = true
    },
  })

  export default class Header extends Vue {}
