export default function authHeader() {
  let token = JSON.parse(localStorage.getItem('accessToken') || '{}');

  if (token) {
    return {
      Authorization: 'Bearer ' + token,
      // //CORS
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
    }
  } else {
    return {}
  }
}
