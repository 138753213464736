// import * as dotenv from 'dotenv'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap'
import LocalStorage from '@/utils/localstorage'
import axios from 'axios'
import './scss/app.scss'
import NProgress from 'nprogress'
import authHeader from '@/http/auth.header'
import authHeaderRefresh from '@/http/auth.header.refresh'
import { authService } from '@/http/auth'

// dotenv.config()

// var host = window.location.protocol + '//api.' + window.location.hostname
var host = process.env.VUE_APP_BASE_URL
if (!host) {
  host = 'http://localhost:8082/'
}
LocalStorage.set('base_url', host)

// Axios Setup
axios.defaults.baseURL = host
axios.defaults.headers.common = authHeader()
// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})
// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  NProgress.done()
  return response
}, function (error) {
  const originalRequest = error.config
  NProgress.done()
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    axios.defaults.headers.common = authHeaderRefresh()
    Promise.resolve(authService.refreshAccessToken())
    .then(() => {
      axios.defaults.headers.common = authHeader()
      const access_token = JSON.parse(localStorage.getItem('accessToken') || '{}')
      originalRequest.headers.Authorization = 'Bearer ' + access_token
      axios(originalRequest)
      // window.location.reload()
      window.location.href = "/user/bill"
    }).catch(() => {
      authService.logout()
    })
  } else if (error.response.status == 401) {
    LocalStorage.clearAdmin()
    LocalStorage.clearUser()
    window.location.href = "/auth"
  }
  return Promise.reject(error)
})
createApp(App).use(router).mount('#app')
